import apiService from "./apiService";

const officeService = {
    async getOffices() {
        try {
            return (await apiService.get(`office/getoffices`, {}, false)).data;
        }
        catch {
            return [];
        }
    },
    async save(office) {
        try {
            return (await apiService.post(`office/save`, office, false)).data
        }
        catch {
            return { success: false, message: 'An error occured!' }
        }
    },
    async saveService(service) {
        try {
            return (await apiService.post(`office/save-service`, service, false)).data
        }
        catch {
            return { success: false, message: 'An error occured!' }
        }
    },
    async removeAttachment(id){
        try{
            return (await apiService.post(`office/remove-att/${id}`,{},true)).data;
        }
        catch{
            return {success: false, message: "An error occured while processing the request."}
        }
    },
    async removeService(id){
        try{
            return (await apiService.post(`office/remove-service/${id}`,{},true)).data;
        }
        catch{
            return {success: false, message: "An error occured while processing the request."}
        }
    },
    async removeOffice(id){
        try{
            return (await apiService.post(`office/remove-office/${id}`,{},true)).data;
        }
        catch{
            return {success: false, message: "An error occured while processing the request."}
        }
    }
}

export default officeService;
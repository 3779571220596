<template>
  <!-- Modal Structure -->
  <div
    :class="['modal', 'fade', { show: isVisible }]"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    id="confirmModal"
    tabindex="-1"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title d-flex justify-content-start align-items-center"
            id="confirmModalLabel"
          >
            <i class="bi bi-shield-check text-success fs-2"></i> Confirmation
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="confirmNo"
          ></button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="confirmYes">Yes</button>
          <button type="button" class="btn btn-secondary" @click="confirmNo">No</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    // Computed properties to access Vuex store state
    const isVisible = computed(() => store.state.confirmModal.isVisible);
    const message = computed(() => store.state.confirmModal.message);

    // Methods to dispatch Vuex actions
    const confirmYes = () => {
      store.dispatch("confirmYes");
    };

    const confirmNo = () => {
      store.dispatch("confirmNo");
    };

    return {
      isVisible,
      message,
      confirmYes,
      confirmNo,
    };
  },
};
</script>
<style scoped>
.modal.show {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}
</style>

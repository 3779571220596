import NotFound from '@/pages/NotFound.vue';
import { getUserRoles, isAuthenticated } from '@/utils/auth';
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { roles } from '@/utils/config';
import ForbiddenPage from '@/pages/ForbiddenPage.vue';
import HomePage from '@/pages/HomePage.vue';
import AdminPage from '@/pages/AdminPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        meta: { requiresAuth: false, showHeader: true, roles: roles.adminDashboardRoles }
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminDashboardRoles }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: { requiresAuth: false, showHeader: true, roles: roles.adminDashboardRoles }
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: ForbiddenPage,
        meta: { showHeader: false }
    },
    { path: '/:catchAll(.*)', component: NotFound, meta: { showHeader: false } }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash };
        }
        return savedPosition || { top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    // Check if the route requires authentication and the user is authenticated
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // Redirect to login page with return URL as query parameter
        return next({
            path: '/login',
            query: { return_url: to.fullPath }
        });
    }

    // Handle role-based access control
    if (to.meta.roles && to.meta.roles.length > 0) {
        const userRoles = getUserRoles(); // Example function to get user roles
        const allowedRoles = to.meta.roles;

        // Check if any of the user's roles match the allowed roles
        const hasAccess = userRoles.some(role => allowedRoles.includes(role));

        if (!hasAccess) {
            // Redirect to unauthorized page or handle unauthorized access
            return next('/forbidden'); // Example redirect to unauthorized page
        }
    }

    // Update header visibility based on the route meta
    const showHeader = to.meta.showHeader !== false; // Default to true if not explicitly set
    store.dispatch("showAdminHeader", showHeader);

    // Continue to the route
    next();
});


export default router;

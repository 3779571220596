<template>
  <spinner-overlay />
  <confirm-dialog />
  <div id="app" class="d-flex flex-column min-vh-100">
    <header class="bg-danger text-white text-center py-3 fixed-top">
      <h1>
        <router-link
          to="/"
          class="d-flex justify-content-center align-items-center v-banner"
        >
          <img class="rounded-circle me-2" height="60" width="60" src="/lgulogo.png" />
          LGU Palompon Citizen`s Charter
        </router-link>
      </h1>
    </header>

    <!-- Main Content -->
    <main class="my-4 flex-grow-1 p-3">
      <div class="row mt-5 pt-5">
        <router-view></router-view>
      </div>
    </main>

    <!-- Footer -->
    <footer class="bg-secondary text-white text-center py-2 mt-auto">
      <p class="mt-3">&copy; Powerd By: <b>Exodous I.T. Solutions</b></p>
    </footer>
  </div>
</template>

<script>
import ConfirmDialog from "./components/Reusables/ConfirmDialog.vue";
import SpinnerOverlay from "./components/Reusables/SpinnerOverlay.vue";

export default {
  name: "App",
  components: {
    SpinnerOverlay,
    ConfirmDialog,
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* The main area grows to fill the remaining space */
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Style the footer to remain at the bottom */
footer {
  margin-top: auto;
}
.v-banner {
  text-decoration: none;
  color: rgb(253, 249, 249);
}
</style>

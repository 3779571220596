<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div
        class="col-md-4 mb-4 text-center"
        v-for="(office, index) in offices"
        :key="office.id"
        @touchstart="touchStart"
        @touchend="touchEnd"
        data-aos="fade-up"
        :data-aos-delay="index * 200"
      >
        <div class="card h-100">
          <div class="card-body">
            <i :class="office.icon" class="display-1 mb-4 text-danger"></i>
            <h5 class="card-title">{{ office.shortName }}</h5>
            <div class="card-text" v-html="sanitizeHtml(office.description)"></div>
            <div
              @click.prevent="handleServiceClick(service)"
              style="cursor: pointer"
              class="text-start"
              v-for="service in office.services"
              :key="service.id"
            >
              <p>
                <i class="bi bi-caret-right-fill me-2 text-danger"></i>
                <span class="fw-bold">{{ service.name }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MediaViewer ref="mediaViewer" />
</template>

<script>
import { onMounted, ref } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";
import MediaViewer from "./MediaViewer.vue";
import officeService from "@/services/officeService";
import DOMPurify from "dompurify";
export default {
  name: "OfficeDirectory",
  components: {
    MediaViewer,
  },
  setup() {
    onMounted(async () => {
      offices.value = await officeService.getOffices();
      animate();
    });

    const animate = () => {
      AOS.init({
        duration: 1000, // Animation duration
        once: true, // Whether animation should happen only once
      });
    };
    const sanitizeHtml = (raw) => {
      return DOMPurify.sanitize(raw);
    };
    const mediaViewer = ref(null);

    const offices = ref([]);

    const viewOfficeDetails = (office) => {
      const mediaItems = office.attachments;
      //const mediaViewer = document.querySelector('MediaViewer');
      if (mediaViewer.value) {
        mediaViewer.value.openModal(mediaItems);
      } else {
        alert("asd");
      }
    };

    const handleServiceClick = (service) => {
      const mediaItems = service.attachments;
      //const mediaViewer = document.querySelector('MediaViewer');
      if (mediaViewer.value) {
        mediaViewer.value.openModal(mediaItems);
      } else {
        alert("asd");
      }
    };

    return {
      offices,
      viewOfficeDetails,
      mediaViewer,
      sanitizeHtml,
      handleServiceClick,
    };
  },
};
</script>

<style scoped>
/* Custom styles for the kiosk */

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 15px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-text {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.btn {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
}

.display-1 {
  font-size: 3rem;
  color: #007bff;
}
</style>

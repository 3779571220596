import { getUserRoles } from "./auth";

const apiConfig = {
  baseUrl: process.env.VUE_APP_API_URL || 'https://localhost:7045/v1/',
};

const roles = {
  adminTeller: ['admin', 'superadmin', 'attendant', 'teller'],
  superAdmin: ['developer', 'superadmin'],
  adminVoters: ['admin', 'voters'],
  adminDashboard: ['admin', 'dashboard'],
  adminHousehold: ['admin', 'households'],
  adminHeatMap: ['admin', 'heatmap'],
  adminHeatMapSetup: ['admin', 'heatmap-setup'],
  adminPrint: ['admin','id-print'],
  adminLogs: ['admin','user-logs'],
  adminBarangaySettings: ['admin', 'brgy-setup'],
  adminCitySettings: ['admin', 'city-setup'],
  adminUserSettings: ['admin', 'user-setup'],
  adminExport:['admin','web-export']
};
const getPresetRoles = () => {
  const userRoles = getUserRoles(); // Assume this function returns an array of roles

  // Base set of roles that apply to all users
  const baseRoles = [
    "android-dashboard",
    "android-login",
    "android-revoke-voter",
    "android-scan",
    "android-validate-qr",
    "android-upload",
    "android-online-search",
    "android-edit-voter",
    "android-voter",
    "android-database",
    "android-household",
    "brgy-setup",
    "city-setup",
    "heatmap",
    "heatmap-setup",
    "households",
    "id-print",
    "user-setup",
    "user-logs",
    "voters",
    "web-dashboard",
    "web-export",
    "web-login",
    "web-scan-live",
    "web-scan-print",
    "web-validate-qr",
    "web-voter-add",
    "web-voter-mod",
  ];

  // Additional roles for superadmin
  const superadminRoles = ["admin"];

  // Check if user has the superadmin role
  if (userRoles.includes('superadmin')) {
    return [...superadminRoles, ...baseRoles];
  } else {
    return baseRoles;
  }
};

const processRolesString = (rolesString) => {
  if (rolesString) {
    // Check if rolesString is not null or empty
    return rolesString.split(",").map((role) => role.trim());
  } else {
    return []; // Default to an empty array if rolesString is null or empty
  }
};

const extractAssignedBrgys = (brgyClaim) => {
  // Default empty string if brgyClaim is null or undefined
  const temp = brgyClaim || "";
  
  // Split the claim value by comma
  const arr = temp.split(',');
  const ret = [];
  
  arr.forEach(item => {
      // Trim any extra whitespace
      item = item.trim();
      
      // Check if item contains a hyphen
      if (!item.includes('-')) {
          // If no hyphen, add the item directly
          ret.push(Number(item));
      } else {
          // If hyphen exists, process the range
          const range = item.split('-');
          if (range.length === 2) {
              const start = parseInt(range[0].trim(), 10);
              const end = parseInt(range[1].trim(), 10);
              
              if (!isNaN(start) && !isNaN(end)) {
                  for (let i = start; i <= end; i++) {
                      ret.push(Number(i.toString()));
                  }
              } else {
                  console.error("Invalid number format in range: " + item);
              }
          }
      }
  });
  
  // Return the result array
  return ret;
}


export { apiConfig, roles, processRolesString, getPresetRoles,extractAssignedBrgys };
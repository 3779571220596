<template>
  <div>
    <div class="text-end">
      <input
        type="file"
        ref="fileInput"
        accept="image/*,video/*"
        style="display: none"
        @change="handleFileSelected"
      />
      <button type="button" @click="handleAddNew" class="btn btn-success">
        <i class="bi bi-plus"></i>
      </button>
    </div>
    <div class="table-responsive">
      <table class="table table-striped align-middle">
        <thead>
          <tr>
            <th></th>
            <th>Short Name</th>
            <th>Complete Name</th>
            <th>Description</th>
            <th>Created</th>
            <th>Updated</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="clickable"
            @click.prevent="handleClick(office)"
            v-for="office in offices"
            :key="office.id"
          >
            <td>
              <i class="text-danger" :class="office.icon"></i>
            </td>
            <td>
              {{ office.shortName }}
            </td>
            <td>
              {{ office.fullName }}
            </td>
            <td>
              {{ office.description }}
            </td>
            <td>
              {{ office.created }}
            </td>
            <td>
              {{ office.updated }}
            </td>
            <td>
              <button
                @click.prevent="handleDelete(office.id)"
                title="Delete"
                class="btn btn-sm btn-danger"
              >
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal-overlay">
      <button class="modal-close" @click="closeModal">&times;</button>
      <div class="modal-content">
        <div class="container bg-white" style="max-height: 80vh; overflow-y: auto">
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <h3 class="text-center my-2">Office Information</h3>
              <div class="form-group">
                <label>Short Name:</label>
                <input class="form-control" v-model="selectedOffice.shortName" />
              </div>
              <div class="form-group">
                <label>Full Name:</label>
                <input required class="form-control" v-model="selectedOffice.fullName" />
              </div>
              <div class="form-group">
                <label>Description:</label>
                <textarea
                  required
                  rows="5"
                  class="form-control"
                  v-model="selectedOffice.description"
                ></textarea>
              </div>
              <div class="form-group">
                <label>Icon:</label>
                <div class="input-group">
                  <span class="input-group-text" :class="selectedOffice.icon"></span>
                  <input required class="form-control" v-model="selectedOffice.icon" />
                </div>
              </div>
              <h5 class="d-flex justify-content-between align-items-center mt-3">
                <span>Services</span>
                <button
                  type="button"
                  title="Add Service"
                  @click.prevent="handleAddService(selectedOffice.id)"
                  class="btn btn-success"
                >
                  <i class="bi bi-plus"></i>
                </button>
              </h5>
              <div class="table-responsive">
                <table class="table align-middle">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="
                        !selectedOffice.services || selectedOffice.services.length === 0
                      "
                    >
                      <td class="text-center" colspan="2">No service added yet</td>
                    </tr>
                    <tr
                      v-for="(service, index) in selectedOffice.services"
                      :key="service.id"
                    >
                      <div class="table-responsive">
                        <table class="table align-middle">
                          <thead>
                            <tr>
                              <th
                                style="cursor: pointer"
                                @click="handleEditService(service)"
                              >
                                <i class="bi bi-pencil me-1"></i>{{ service.name }}
                              </th>
                              <th class="text-end" style="min-width: 100px">
                                <div
                                  class="d-flex justify-content-end align-items-center"
                                >
                                  <button
                                    type="button"
                                    @click.prevent="
                                      handleRemoveService(service.id, index)
                                    "
                                    class="btn btn-danger btn-sm me-3"
                                    title="Delete Service"
                                  >
                                    <i class="bi bi-trash"></i>
                                  </button>
                                  <button
                                    type="button"
                                    @click.prevent="handleAddAttachment(service)"
                                    class="btn btn-success btn-sm"
                                    title="Add Attachment"
                                  >
                                    <i class="bi bi-plus"></i>
                                  </button>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="att in service.attachments" :key="att.id">
                              <td>{{ att.url }}</td>
                              <td>
                                <div
                                  class="d-flex justify-content-end align-items-center"
                                >
                                  <button
                                    type="button"
                                    @click="removeAttachment(service, att.id)"
                                    class="btn btn-sm btn-danger"
                                  >
                                    <i class="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-group">
                <button class="btn btn-success w-100">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Modal for adding service -->
    <div v-if="showServiceModal" class="modal-overlay" style="z-index: 88888">
      <button class="modal-close" @click="toggleServiceModal">&times;</button>
      <div class="modal-content">
        <div class="container bg-white">
          <form @submit.prevent="handleSubmitService">
            <div class="row">
              <h5 class="text-center">Service Information</h5>
              <div class="form-group">
                <label>Service Name:</label>
                <input
                  required
                  class="form-control"
                  v-model.trim="selectedService.name"
                />
              </div>
              <div class="form-group">
                <label>Description:</label>
                <textarea
                  required
                  class="form-control"
                  v-model.trim="selectedService.description"
                ></textarea>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary w-100 mt-3">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import officeService from "@/services/officeService";
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import { ref, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { apiConfig } from "@/utils/config";
import axios from "axios";
import confirmationService from "@/services/confirmationService";
import store from "@/store";
export default {
  setup() {
    const fileInput = ref(null);
    const offices = ref([]);
    const showModal = ref(false);
    const isButtonClicked = ref(false);
    const selectedOffice = ref({});
    const showServiceModal = ref(false);
    const selectedService = ref({});
    const serviceIdToAttach = ref("00000000-0000-0000-0000-000000000000");
    const handleSubmit = async () => {
      spinnerService.show();
      const response = await officeService.save(selectedOffice.value);
      if (response.success) {
        toastService.success("Action successful!");
        closeModal();
        await getOffices();
      } else {
        toastService.error(response.message);
      }
      spinnerService.hide();
    };
    const handleAddService = (id) => {
      selectedService.value = {
        officeId: id,
        id: "00000000-0000-0000-0000-000000000000",
        attachments: [],
      };
      toggleServiceModal();
    };
    const toggleServiceModal = () => {
      showServiceModal.value = !showServiceModal.value;
    };
    const handleDelete = async (id) => {
      isButtonClicked.value = true;
      setTimeout(() => {
        isButtonClicked.value = false;
      }, 200);
      const confirmed = await confirmationService.show(
        "Are you sure you want to delete this item?"
      );
      if (confirmed) {
        deleteOffice(id);
      }
    };

    const deleteOffice = async (id) => {
      spinnerService.show();
      const response = await officeService.removeOffice(id);
      if (response.success) {
        toastService.success("Action successful");
        getOffices();
      } else {
        toastService.error(response.message);
      }
      spinnerService.hide();
    };

    const handleSubmitService = async () => {
      spinnerService.show();
      const response = await officeService.saveService(selectedService.value);
      if (response.success) {
        if (response.hasNextAction) {
          selectedService.value.id = response.ref;
          selectedService.value.attachments = [];
          selectedOffice.value.services.push(selectedService.value);
        } else {
          let idx = selectedOffice.value.services.findIndex(
            (x) => x.id === selectedService.value.id
          );
          if (idx > -1) {
            selectedOffice.value.services[idx] = selectedService.value;
          }
        }
        toastService.success("Action successful");
        toggleServiceModal();
      } else {
        toastService.error(response.message);
      }
      spinnerService.hide();
    };
    const uploadFileInChunks = async (file, fileName, fileExt, serviceId) => {
      spinnerService.show();
      const chunkSize = 1024 * 1024; // 1MB per chunk
      const totalChunks = Math.ceil(file.size / chunkSize);

      for (let i = 0; i < totalChunks; i++) {
        const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("fileName", fileName);
        formData.append("fileExt", fileExt); // Pass the file extension separately
        formData.append("chunkNumber", i + 1);
        formData.append("totalChunks", totalChunks);
        formData.append("serviceId", serviceId); // Pass officeId here

        try {
          const response = await axios.post(
            `${apiConfig.baseUrl}office/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${store.getters.currentUser?.accessToken}`,
              },
            }
          );
          if (response.data.success && !response.data.hasNextAction) {
            const service = selectedOffice.value.services.find((x) => x.id === serviceId);
            if (service) {
              service.attachments.push({
                id: response.data.ref,
                url: response.data.message,
              });
            }
          }
        } catch (error) {
          console.error("Error uploading chunk:", error);
        }
      }
      spinnerService.hide();
      toastService.success("Upload Sucessful!");
    };

    const handleRemoveService = async (serviceId, index) => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to remove this service?"
      );
      if (!confirmed) return;
      spinnerService.show();
      const response = await officeService.removeService(serviceId);
      if (response.success) {
        selectedOffice.value.services.splice(index, 1);
      } else {
        toastService.error(response.message);
      }
      spinnerService.hide();
    };

    const getOffices = async () => {
      offices.value = await officeService.getOffices();
    };

    const handleClick = (office) => {
      if (isButtonClicked.value === true) return;

      selectedOffice.value = { ...office };

      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const handleAddNew = () => {
      showModal.value = true;
      selectedOffice.value = { id: "00000000-0000-0000-0000-000000000000" };
    };

    const handleAddAttachment = (service) => {
      selectedService.value = service;
      if (selectedService.value.id === "00000000-0000-0000-0000-000000000000") {
        toastService.error("Please save the the office first before adding attachments");
        return;
      }
      serviceIdToAttach.value = service.id;
      fileInput.value.click();
    };
    const handleFileSelected = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExt = file.name.split(".").pop(); // Get the file extension
        const fileName = uuidv4(); // Generate a GUID for the file name

        await uploadFileInChunks(file, fileName, fileExt, serviceIdToAttach.value);
      }
    };
    const handleEditService = (service) => {
      selectedService.value = { ...service };
      toggleServiceModal();
    };
    const removeAttachment = async (service, id) => {
      const confirmed = await confirmationService.show("Are you sure want to proceed?");
      if (!confirmed) {
        return;
      }
      const response = await officeService.removeAttachment(id);
      if (response.success) {
        let idx = service.attachments.findIndex((x) => x.id === id);
        if (idx > -1) {
          service.attachments.splice(idx, 1);
        }
        toastService.success("Action successfull");
        getOffices();
      } else {
        toastService.error(response.message);
      }
    };
    onMounted(() => {
      getOffices();
    });

    return {
      offices,
      showModal,
      selectedOffice,
      handleClick,
      closeModal,
      handleSubmit,
      handleAddNew,
      handleAddAttachment,
      fileInput,
      handleFileSelected,
      removeAttachment,
      showServiceModal,
      toggleServiceModal,
      handleAddService,
      selectedService,
      handleSubmitService,
      handleEditService,
      handleRemoveService,
      handleDelete,
    };
  },
};
</script>

<style scoped>
tr.clickable {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  /* Ensure content doesn't overflow the screen vertically */
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  /* Set a max-width to keep the modal content responsive */
  width: 600px;
  /* Define a default width */
  box-sizing: border-box;
  overflow: hidden;
  /* Ensures that content doesn't overlap the close button */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 1000;
}
</style>

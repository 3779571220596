<template>
  <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <button class="close-button" @click="closeModal">&times;</button>
      <div class="media-container">
        <div v-if="currentMedia">
          <div v-if="currentMedia.attachmentType === 'images'">
            <img
              :src="`${apiConfig.baseUrl}${currentMedia.url}`"
              class="modal-image"
              @load="mediaLoaded"
              @error="mediaLoadError"
              v-show="!loading"
              ref="mediaElement"
            />
            <div v-if="loading" class="loading-spinner"></div>
          </div>
          <div v-if="currentMedia.attachmentType === 'videos'">
            <video
              controls
              class="modal-video"
              @loadeddata="mediaLoaded"
              @error="mediaLoadError"
              v-show="!loading"
              ref="mediaElement"
            >
              <source :src="`${apiConfig.baseUrl}${currentMedia.url}`" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div v-if="loading" class="loading-spinner"></div>
          </div>
        </div>
        <button
          v-if="mediaItems.length > 1 && currentIndex > 0"
          class="prev-button"
          @click="prevMedia"
        >
          <i class="bi bi-arrow-left-short" style="font-size: 5rem"></i>
        </button>
        <button
          v-if="mediaItems.length > 1 && currentIndex < mediaItems.length - 1"
          class="next-button"
          @click="nextMedia"
        >
          <i class="bi bi-arrow-right-short" style="font-size: 5rem"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { apiConfig } from "@/utils/config";
import { ref, computed, defineExpose } from "vue";

const showModal = ref(false);
const mediaItems = ref([]);
const currentIndex = ref(0);
const loading = ref(true); // Track loading state

const currentMedia = computed(() => mediaItems.value[currentIndex.value] || null);
// eslint-disable-next-line
function openModal(items) {
  mediaItems.value = items;
  currentIndex.value = 0;
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
}

function nextMedia() {
  if (currentIndex.value < mediaItems.value.length - 1) {
    currentIndex.value++;
    loading.value = true; // Show loading when changing media
  }
}

function prevMedia() {
  if (currentIndex.value > 0) {
    currentIndex.value--;
    loading.value = true; // Show loading when changing media
  }
}
function mediaLoadError() {
  loading.value = false;
}
// Event handler for media load complete
function mediaLoaded() {
  loading.value = false; // Hide loading spinner
}
defineExpose({ openModal });
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.media-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.modal-video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  background: transparent;
  border: none;
  color: #fc0707;
  cursor: pointer;
  z-index: 1000;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  font-size: 36px;
  background: transparent;
  /* Make the background transparent */
  border: none;
  color: #f31111;
  /* Ensure the text color is white for visibility */
  cursor: pointer;
  transform: translateY(-50%);
  /* Optional: Add a subtle shadow to make the buttons stand out */
}

.prev-button {
  left: -45px;
}

.next-button {
  right: -45px;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@media (max-width: 768px) {
  .prev-button {
    left: 0px;
  }

  .next-button {
    right: 0px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

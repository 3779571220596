<template>
  <div class="container-fuid d-flex justify-content-center">
    <div class="col-md-4 col-lg-3 col-12 mt-4 bg-light p-4 rounded-3">
      <div class="text-center mb-4">
        <i class="bi bi-shield-lock-fill text-danger display-4"></i>
      </div>
      <h3 class="text-center mb-5 mt-2">Login Page</h3>
      <form @submit.prevent="handleSubmit">
        <div class="form-group mb-3">
          <label>Username:</label>
          <div class="input-group">
            <span class="input-group-text bi bi-person-fill"></span>
            <input required v-model="user.username" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group mb-3">
          <label>Password:</label>
          <div class="input-group">
            <span class="input-group-text bi bi-lock-fill"></span>
            <input
              required
              v-model="user.password"
              type="password"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <p v-if="errmsg !== null" class="text-danger fst-italic">
            <span class="bi bi-exclamation-diamond mx-1"></span> {{ errmsg }}
          </p>
        </div>
        <button type="submit" class="btn btn-danger w-100 mb-3">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import store from "@/store";
import { ref } from "vue";

export default {
  setup() {
    const returnURL = router.currentRoute.value.query.return_url || "/";
    const user = ref({ username: "", password: "" });
    const handleSubmit = async () => {
      errmsg.value = null;
      spinnerService.show();
      try {
        await store.dispatch("auth/login", {
          username: user.value.username,
          password: user.value.password,
        });
        router.replace(returnURL);
      } catch (error) {
        if (error.error) {
          errmsg.value = error.error.message;
          toastService.error(error.error.message);
        } else {
          errmsg.value = "An error occured while processing the request.";
          toastService.error("An error occured while processing the request.");
        }
      }

      spinnerService.hide();
    };
    const errmsg = ref(null);
    return {
      user,
      handleSubmit,
      errmsg,
    };
  },
};
</script>

import * as signalR from '@microsoft/signalr';
import store from '@/store';
import { apiConfig } from '@/utils/config';

class SignalRService {
  constructor() {
    this.connection = null;
    this.reconnectInterval = 5000; // Time between reconnection attempts in milliseconds
    this.eventHandlers = {}; // Store event handlers
  }

  initializeConnection() {
    this.createConnection();

    this.connection.start()
      .then(() => {
        this.reSubscribeToEvents();
      })
      .catch(() => {
        this.handleReconnection();
      });

    this.connection.onclose(() => {
      this.handleReconnection();
    });

    this.connection.onreconnected(() => {
      this.reSubscribeToEvents();
    });
  }
  async getPaginatedUsers(pageNumber, pageSize) {
    try {
      return await this.connection.invoke('GetPaginatedUsers', pageNumber, pageSize);
    } catch {
      return { users: [], totalCount: 0 };
    }
  }
  createConnection() {
    const currentUser = store.getters['auth/currentUser'];
    if (currentUser === null)
      return;
    const accessToken = currentUser.accessToken;
    const baseURL = apiConfig.baseUrl.replace('/v1/', '');
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${baseURL}/notificationHub`, {
        accessTokenFactory: () => accessToken,
      //  transport: signalR.HttpTransportType.WebSockets,
        headers: {
          // Include cookies in the headers
          //"Cookie": document.cookie // Add cookies from the document
        }
      })
      //.configureLogging(signalR.LogLevel.Information)
      .build();
  }

  handleReconnection() {
    if (this.isAuthenticated()) {
      setTimeout(() => {
        this.createConnection();
        this.initializeConnection();
      }, this.reconnectInterval);
    }
  }

  isAuthenticated() {
    return !!store.getters['auth/currentUser'].accessToken;
  }

  subscribeToEvent(eventName, callback) {
    if (this.connection) {
      if (!this.eventHandlers[eventName]) {
        this.eventHandlers[eventName] = [];
      }
      this.eventHandlers[eventName].push(callback);
      this.connection.on(eventName, callback);
    } else {
      console.error('Connection is not established.');
    }
  }

  reSubscribeToEvents() {
    for (const [eventName, handlers] of Object.entries(this.eventHandlers)) {
      handlers.forEach(handler => {
        this.connection.on(eventName, handler);
      });
    }
  }
}

export default new SignalRService();
